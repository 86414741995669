import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useRef, useState } from 'react';

const Collapsible = ({ open, children, title }) => {
  const ref = useRef();

  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState(open ? undefined : 0);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <>
      <div
        className="acc-panel"
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        onKeyDown={handleOpen}
      >
        <h3>{title}</h3>
        {isOpen ? (
          <i className="fa fa-angle-up"></i>
        ) : (
          <i className="fa fa-angle-down"></i>
        )}
      </div>
      <div className="collapsible-content" style={{ height }}>
        <div ref={ref}>
          <div className="panel-content">{children}</div>
        </div>
      </div>
    </>
  );
};

Collapsible.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Collapsible;
