import React from 'react';

import Collapsible from '../components/collapsible';
import ContentSection from '../components/content-section';
import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const PreisePage = (props) => {
  const title = 'Preise';

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Die Preisliste richtet sich nach dem verfügbaren Standardangebot. Über Preise für Spezialangebote oder individuelle Wünsche geben wir gerne Auskunft."
      />

      <IntroText title={title}>
        Die Preisliste richtet sich nach dem verfügbaren Standardangebot. Über
        Preise für Spezialangebote oder individuelle Wünsche geben wir gerne
        Auskunft.
        <br />
        <br />
        <a
          href={'/assets/preisliste_2024.pdf'}
          target="_blank"
          rel="noopener noreferrer"
          className="small"
        >
          <i className="fa fa-external-link"></i> Preisliste als PDF
          herunterladen
        </a>
      </IntroText>

      <ContentSection light>
        <div className="entry-wrap toggle">
          <Collapsible title="Damen">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Formen/Legen</td>
                    <td className="text-right">52.-</td>
                  </tr>
                  <tr>
                    <td>Formen mit Hand</td>
                    <td className="text-right">37.-</td>
                  </tr>
                  <tr>
                    <td>Schneiden</td>
                    <td className="text-right">46.-</td>
                  </tr>
                  <tr>
                    <td>Nacken, Fransen oder Konturen</td>
                    <td className="text-right">10.- bis 20.-</td>
                  </tr>
                  <tr>
                    <td>Waschen, schneiden, föhnen Kurzhaar</td>
                    <td className="text-right">76.-</td>
                  </tr>
                  <tr>
                    <td>Waschen, schneiden, föhnen Langhaar</td>
                    <td className="text-right">86.-</td>
                  </tr>
                  <tr>
                    <td>Hochstecken/Gala Frisur</td>
                    <td className="text-right">ab 50.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Herren">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Waschen, schneiden, formen</td>
                    <td className="text-right">50.- bis 60.-</td>
                  </tr>
                  <tr>
                    <td>Nass schneiden, formen</td>
                    <td className="text-right">38.- bis 43.-</td>
                  </tr>
                  <tr>
                    <td>Ganz mit Maschine</td>
                    <td className="text-right">20.- bis 30.-</td>
                  </tr>
                  <tr>
                    <td>Bart und Schnauz</td>
                    <td className="text-right">20.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Kinder">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0 - 6 Jahre</td>
                    <td className="text-right">10.- bis 24.-</td>
                  </tr>
                  <tr>
                    <td>7 - 13 Jahre</td>
                    <td className="text-right">25.- bis 34.-</td>
                  </tr>
                  <tr>
                    <td>Girls 14 - 16 Jahre</td>
                    <td className="text-right">47.- bis 53.-</td>
                  </tr>
                  <tr>
                    <td>Boys 14 - 16 Jahre</td>
                    <td className="text-right">29.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Dauerwellen">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Dauerwelle teil</td>
                    <td className="text-right">66.-</td>
                  </tr>
                  <tr>
                    <td>Dauerwelle ganz Kurzhaar</td>
                    <td className="text-right">82.-</td>
                  </tr>
                  <tr>
                    <td>Dauerwelle ganz Langhaar</td>
                    <td className="text-right">96.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Farbe">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Farbe bis 40 Gramm (Ansatz)</td>
                    <td className="text-right">55.-</td>
                  </tr>
                  <tr>
                    <td>Farbe bis 60 Gramm (Kurz)</td>
                    <td className="text-right">61.-</td>
                  </tr>
                  <tr>
                    <td>Farbe bis 100 Gramm (Lang)</td>
                    <td className="text-right">70.-</td>
                  </tr>
                  <tr>
                    <td>Vero Glaze bis 50 Gramm (Kurz)</td>
                    <td className="text-right">55.-</td>
                  </tr>
                  <tr>
                    <td>Vero Glaze bis 90 Gramm (Lang)</td>
                    <td className="text-right">65.-</td>
                  </tr>
                  <tr>
                    <td>Tönen bis 50 Gramm (Kurz)</td>
                    <td className="text-right">49.-</td>
                  </tr>
                  <tr>
                    <td>Tönen bis 100 Gramm (Lang)</td>
                    <td className="text-right">56.-</td>
                  </tr>
                  <tr>
                    <td>Méches pro Folie</td>
                    <td className="text-right">10.-</td>
                  </tr>
                  <tr>
                    <td>Farb-Méches teil</td>
                    <td className="text-right">65.- bis 70.-</td>
                  </tr>
                  <tr>
                    <td>Farb-Méches ganz</td>
                    <td className="text-right">80.- bis 95.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Make-up">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tages Make-up</td>
                    <td className="text-right">45.- bis 55.-</td>
                  </tr>
                  <tr>
                    <td>Abend/Gala Make-up</td>
                    <td className="text-right">65.- bis 85.-</td>
                  </tr>
                  <tr>
                    <td>Foto Make-up</td>
                    <td className="text-right">auf Anfrage</td>
                  </tr>
                  <tr>
                    <td>Braut Make-up inkl. Probeschminken</td>
                    <td className="text-right">auf Anfrage</td>
                  </tr>
                  <tr>
                    <td>Persönliche Typenberatung</td>
                    <td className="text-right">auf Anfrage</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Augenbrauen / Wimpern">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Augenbrauen
                      <br />
                      &nbsp;&nbsp;&nbsp;- nur Farbe
                      <br />
                      &nbsp;&nbsp;&nbsp;- mit zupfen
                    </td>
                    <td className="text-right">
                      <br />
                      15.-
                      <br />
                      25.-
                    </td>
                  </tr>
                  <tr>
                    <td>Wimpern färben</td>
                    <td className="text-right">25.-</td>
                  </tr>
                  <tr>
                    <td>
                      Augenbrauen und Wimpern
                      <br />
                      &nbsp;&nbsp;&nbsp;- nur Farbe
                      <br />
                      &nbsp;&nbsp;&nbsp;- mit zupfen
                    </td>
                    <td className="text-right">
                      <br />
                      30.-
                      <br />
                      45.-
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Diverses">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Dienstleistung</th>
                    <th className="text-right">Preis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>K-Pak-Kur (1x)</td>
                    <td className="text-right">63.-</td>
                  </tr>
                  <tr>
                    <td>K-Pak-Kur (2x)</td>
                    <td className="text-right">78.-</td>
                  </tr>
                  <tr>
                    <td>K-Pak-Kur (3x)</td>
                    <td className="text-right">97.-</td>
                  </tr>
                  <tr>
                    <td>Packung</td>
                    <td className="text-right">16.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <Collapsible title="Rabatte">
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Beschreibung</th>
                    <th className="text-right">Rabatt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lernende</td>
                    <td className="text-right">10%</td>
                  </tr>
                  <tr>
                    <td>AHV/IV-Bezüger*innen</td>
                    <td className="text-right">10%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapsible>

          <p className="mt-20">Alle Preise in CHF. Änderungen vorbehalten.</p>

          <p className="small">Stand: Januar 2024</p>
        </div>
      </ContentSection>
    </Layout>
  );
};

export default PreisePage;
